import { Injectable } from '@angular/core';
import _get from 'lodash/get';
@Injectable({
  providedIn: 'root',
})
export class FormControlUtils {
  mapProperty({
    entry,
    property,
    defaultValue = '',
  }: {
    entry: any;
    property: string;
    defaultValue?: any;
  }) {
    return _get(entry, property, defaultValue);
  }

  mapPropertyForTime({
    entry,
    property,
    defaultValue = '',
  }: {
    entry: any;
    property: string;
    defaultValue?: any;
  }) {
    const entryCopy: any[] = { ...entry };
    const time = entryCopy[property];
    if (time?.match(/:/g)?.length === 2) {
      entryCopy[`${property}`] = this.formatTime(time);
    }
    return _get(entryCopy, property, defaultValue);
  }

  private formatTime(time: string) {
    return time.replace(/:[^:]+$/, '');
  }
}
