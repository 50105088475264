import { CommonModule } from '@angular/common';
import { CoreDataModule } from '@ioh/core-data';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ModalComponent } from './modal/modal.component';
import { ModalService } from '@services';
import { NgModule } from '@angular/core';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { UiLibraryModule } from '@ioh/ui-library';

@NgModule({
  declarations: [ModalComponent],
  exports: [
    UiLibraryModule,
    FormsModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    CoreDataModule,
    FlexLayoutModule,
    ModalComponent,
  ],
  imports: [CommonModule, MatIconModule, MatDialogModule],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {},
    },
    ModalService,
  ],
})
export class SharedModule {}
