<div>
  <h1
    class="response"
    [ngClass]="{ 'response--success': response, 'response--fail': !response }"
  >
    <i class="material-icons response__icon">
      {{ icon }}
    </i>
    <span class="response__title">
      {{ title }}
    </span>
  </h1>
  <p class="response__text">
    {{ text }}
  </p>
</div>
