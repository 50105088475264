import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'ioh-timeOut-modal',
  styleUrls: ['./timeOut-modal.component.scss'],
  templateUrl: './timeOut-modal.component.html',
})
export class TimeOutModalComponent {
  @Output() renew = new EventEmitter();
  @Output() logout = new EventEmitter();

  logoutAndClose() {
    this.logout.emit();
  }

  renewToken() {
    this.renew.emit();
  }
}
