import { AbstractControl, ControlContainer } from '@angular/forms';
import { Component, Input } from '@angular/core';
@Component({
  selector: 'ioh-external',
  styleUrls: ['./external.component.scss'],
  templateUrl: './external.component.html',
})
export class ExternalComponent {
  parentFormGroup: AbstractControl;
  @Input() externalOrganizationsCategories$;
  constructor(private readonly controlContainer: ControlContainer) {}

  ngOnInit() {
    this.parentFormGroup = this.controlContainer.control;
  }
}
