import { AdditionalNotesFacade } from '@ioh/core-data';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'ioh-additional-notes',
  styleUrls: ['./additional-notes.component.scss'],
  templateUrl: './additional-notes.component.html',
})
export class AdditionalNotesComponent implements OnInit {
  private readonly subscriptions: Subscription = new Subscription();

  constructor(
    private readonly fb: FormBuilder,
    private readonly additionalNotesFacade: AdditionalNotesFacade
  ) {}

  myGroup: FormGroup;

  ngOnInit() {
    this.myGroup = this.fb.group({
      comments: [['']],
    });

    this.subscriptions.add(
      this.myGroup
        .get('comments')
        .valueChanges.pipe(
          debounceTime(500),
          distinctUntilChanged(),
          map((res) => res)
        )
        .subscribe((res) => {
          this.additionalNotesFacade.updateComments(res);
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
