import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  private readonly clientSearchResults = new BehaviorSubject([]);
  public currentSearchResults = this.clientSearchResults.asObservable();
  public clientSearchQuery = new BehaviorSubject('');
  public clientSearchResultsLoading = new BehaviorSubject(false);

  updateSearchResults(results) {
    this.clientSearchResults.next(results);
  }

  updateSearchQuery(searchQuery) {
    this.clientSearchQuery.next(searchQuery);
  }
}
