import * as ContactDetailsActions from './contact-details.actions';
import * as ContactDetailsSelectors from './contact-details.selectors';
import * as fromContactDetails from './contact-details.reducer';
import { Action, Store, select } from '@ngrx/store';
import { Injectable } from '@angular/core';

@Injectable()
export class ContactDetailsFacade {
  // loaded$ = this.store.pipe(
  //   select(ContactDetailsSelectors.getContactDetailsLoaded)
  // );
  allContactDetails$ = this.store.pipe(
    select(ContactDetailsSelectors.getContactDetailsState)
  );

  allEngagementSponsorResults$ = this.store.pipe(
    select(ContactDetailsSelectors.getAllEngagementSponsorResults)
  );

  allPrimaryContactResults$ = this.store.pipe(
    select(ContactDetailsSelectors.getAllPrimaryContactResults)
  );

  allAdditionalContactResults$ = this.store.pipe(
    select(ContactDetailsSelectors.getAllAdditionalContactResults)
  );

  constructor(
    private readonly store: Store<fromContactDetails.ContactDetailsPartialState>
  ) {}

  dispatch(action: Action) {
    this.store.dispatch(action);
  }

  loadEngagementSponsorSearchResults(value) {
    this.dispatch(
      ContactDetailsActions.loadEngagementSponsorSearchResults({
        searchTerm: value,
      })
    );
  }

  loadPrimaryContactSearchResults(value) {
    this.dispatch(
      ContactDetailsActions.loadPrimaryContactSearchResults({
        searchTerm: value,
      })
    );
  }

  loadAdditionalContactSearchResults(value) {
    this.dispatch(
      ContactDetailsActions.loadAdditionalContactSearchResults({
        searchTerm: value,
      })
    );
  }

  updateEngagementSponsors(value) {
    this.dispatch(
      ContactDetailsActions.updateEngagementSponsors({
        selectedEngagementSponsor: value,
      })
    );
  }

  updatePrimaryContact(value) {
    this.dispatch(
      ContactDetailsActions.updatePrimaryContact({
        selectedPrimaryContact: value,
      })
    );
  }

  updateAdditionalContact(value) {
    this.dispatch(
      ContactDetailsActions.updateAdditionalContact({
        selectedAdditionalContact: value,
      })
    );
  }

  clearStateObject() {
    this.dispatch(ContactDetailsActions.clearStateObject());
  }
}
