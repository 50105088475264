import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  goneFishing: ReplaySubject<boolean> = new ReplaySubject<boolean>();
  canAccess: ReplaySubject<boolean> = new ReplaySubject<boolean>();
}
