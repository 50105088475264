import { CanActivate } from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SharedService } from '../shared/shared.service';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class MaintenanceModeGuard implements CanActivate {
  constructor(
    public sharedService: SharedService,
    @Inject('Window') private readonly window: Window
  ) {}

  canActivate(): Observable<boolean> {
    return this.sharedService.goneFishing.pipe(
      map((isFishing) => {
        if (isFishing) {
          this.window.location.href =
            '../../assets/goneFishing/goneFishing.html';
        }
        return true;
      })
    );
  }
}
