import {
  CONTACTDETAILS_FEATURE_KEY,
  ContactDetailsPartialState,
  ContactDetailsState,
} from './contact-details.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

// Lookup the 'ContactDetails' feature state managed by NgRx
export const getContactDetailsState = createFeatureSelector<
  ContactDetailsPartialState,
  ContactDetailsState
>(CONTACTDETAILS_FEATURE_KEY);

export const getAllEngagementSponsorResults = createSelector(
  getContactDetailsState,
  (state: ContactDetailsState) => state.engagementSponsorSearchResults
);

export const getAllPrimaryContactResults = createSelector(
  getContactDetailsState,
  (state: ContactDetailsState) => state.primaryContactSearchResults
);

export const getAllAdditionalContactResults = createSelector(
  getContactDetailsState,
  (state: ContactDetailsState) => state.additionalContactSearchResults
);
