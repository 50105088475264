import { BackOfficeComponent } from './back-office.component';
import { BannerComponent } from './banner/banner.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuComponent } from './menu/menu.component';
import { RouterModule } from '@angular/router';
import { SearchComponent } from './search/search.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    BannerComponent,
    MenuComponent,
    SearchComponent,
    BackOfficeComponent,
  ],
  exports: [
    BannerComponent,
    MenuComponent,
    SearchComponent,
    BackOfficeComponent,
  ],
  imports: [CommonModule, RouterModule, SharedModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BackOfficeModule {}
