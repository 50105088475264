import { Injectable } from '@angular/core';
import isEmpty from 'lodash/isEmpty';

@Injectable({
  providedIn: 'root',
})
export class NumberUtilsService {
  isGreaterThanNDecimalPoints({ value, decimalPlace }): boolean {
    return String(value).split('.')[1] === undefined
      ? false
      : String(value).split('.')[1].length > decimalPlace;
  }

  isADecimalNumber(input: string | number): boolean {
    return !Number.isInteger(Number(input));
  }

  roundToNDecimalPoints({
    value,
    decimalPlace,
  }: {
    value: string;
    decimalPlace: number;
  }): string {
    if (
      this.isADecimalNumber(value) &&
      this.isGreaterThanNDecimalPoints({ decimalPlace, value })
    ) {
      return Number(value).toFixed(decimalPlace);
      // handle floating/fixed points coming back from DB
    } else if (this.isTrailingZeros(value)) {
      return String(Math.floor(+value));
    }
    return value;
  }

  isTrailingZeros(value: string): boolean {
    const points = !!value && value.split('.')[1]?.split('');
    if (!isEmpty(points)) {
      return points.every((digit) => digit == '0');
    } else return false;
  }

  isJustAPeriod(string: string | number) {
    return typeof string == 'string' && string == '.';
  }

  normalizeToNumbersOnly(value: string | number): number {
    if (this.isJustAPeriod(value)) {
      value = 0;
    } else if (typeof value == 'string') {
      value = value.replace(/[^0-9.]/g, '');
    }
    return Number(value);
  }
}
