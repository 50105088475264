import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'sortDropdown' })
export class SortDropdownPipe implements PipeTransform {
  transform(dropdownArray: any[]): any[] {
    if (Array.isArray(dropdownArray) && dropdownArray.length > 0) {
      const copy: any[] = [...dropdownArray];
      const indexOfOther: number = copy.findIndex((element) =>
        element.value.startsWith('Other')
      );
      const other: any[] = copy.splice(indexOfOther, 1)[0];
      copy.sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));
      copy.push(other);
      return copy;
    }
  }
}
