import { AccordianBodyComponent } from './accordian/accordian-body/accordian-body.component';
import { AccordianComponent } from './accordian/accordian.component';
import { AccordianHeaderComponent } from './accordian/accordian-header/accordian-header.component';
import { AdditionalNotesComponent } from './accordian-sections/additional-notes/additional-notes.component';
import { AttendeeDetailsComponent } from './accordian-sections/attendee-details/attendee-details.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactDetailsComponent } from './accordian-sections/contact-details/contact-details.component';
import { EngagementDetailsComponent } from './accordian-sections/engagement-details/engagement-details.component';
import { FormComponent } from './form.component';
import { SharedModule } from '../shared/shared.module';
import { VisitDetailsComponent } from './accordian-sections/visit-details/visit-details.component';

@NgModule({
  declarations: [
    FormComponent,
    AccordianComponent,
    AccordianBodyComponent,
    AccordianHeaderComponent,
    AdditionalNotesComponent,
    AttendeeDetailsComponent,
    ContactDetailsComponent,
    EngagementDetailsComponent,
    VisitDetailsComponent,
  ],
  exports: [
    FormComponent,
    AccordianComponent,
    AccordianBodyComponent,
    AccordianHeaderComponent,
    AdditionalNotesComponent,
    AttendeeDetailsComponent,
    ContactDetailsComponent,
    EngagementDetailsComponent,
    VisitDetailsComponent,
  ],
  imports: [CommonModule, SharedModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class FormModule {}
