import * as ContactDetailsActions from './contact-details.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { ContactDetailsEntity } from './contact-details.models';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

export const CONTACTDETAILS_FEATURE_KEY = 'contactDetails';

export interface ContactDetailsState extends EntityState<ContactDetailsEntity> {
  selectedId?: string | number; // which ContactDetails record has been selected
  loaded: boolean; // has the ContactDetails list been loaded
  error?: string | null; // last none error (if any)
  engagementSponsorSearchResults: any | null;
  primaryContactSearchResults: any | null;
  additionalContactSearchResults: any | null;
  selectedEngagementSponsor: any | null;
  selectedPrimaryContact: any | null;
  selectedAdditionalContact: any | null;
}

export interface ContactDetailsPartialState {
  readonly [CONTACTDETAILS_FEATURE_KEY]: ContactDetailsState;
}

export const contactDetailsAdapter: EntityAdapter<ContactDetailsEntity> = createEntityAdapter<
  any
>({
  selectId: (contactDetails) => contactDetails.peopleKey,
});

export const initialState: ContactDetailsState = contactDetailsAdapter.getInitialState(
  {
    additionalContactSearchResults: [],

    engagementSponsorSearchResults: [],
    // set initial required properties
    loaded: false,
    primaryContactSearchResults: [],
    selectedAdditionalContact: [],
    selectedEngagementSponsor: [],
    selectedPrimaryContact: [],
  }
);

const contactDetailsReducer = createReducer(
  initialState,
  on(ContactDetailsActions.loadEngagementSponsorSearchResults, (state) => ({
    ...state,
    error: null,
    loaded: false,
  })),
  on(
    ContactDetailsActions.loadEngagementSponsorSearchResultsSuccess,
    (state, { engagementSponsorSearchResults }) =>
      contactDetailsAdapter.addAll(engagementSponsorSearchResults, {
        ...state,
        engagementSponsorSearchResults,
        loaded: true,
      })
  ),
  on(
    ContactDetailsActions.loadEngagementSponsorSearchResultsFailure,
    (state, { error }) => ({
      ...state,
      error,
    })
  ),
  on(
    ContactDetailsActions.updateEngagementSponsors,
    (state, { selectedEngagementSponsor }) => {
      return Object.assign({}, state, { selectedEngagementSponsor });
    }
  ),
  on(ContactDetailsActions.loadPrimaryContactSearchResults, (state) => ({
    ...state,
    error: null,
    loaded: false,
  })),
  on(
    ContactDetailsActions.loadPrimaryContactSearchResultsSuccess,
    (state, { primaryContactSearchResults }) =>
      contactDetailsAdapter.addAll(primaryContactSearchResults, {
        ...state,
        loaded: true,
        primaryContactSearchResults,
      })
  ),
  on(
    ContactDetailsActions.loadPrimaryContactSearchResultsFailure,
    (state, { error }) => ({
      ...state,
      error,
    })
  ),
  on(
    ContactDetailsActions.updatePrimaryContact,
    (state, { selectedPrimaryContact }) => {
      return Object.assign({}, state, { selectedPrimaryContact });
    }
  ),
  on(ContactDetailsActions.loadAdditionalContactSearchResults, (state) => ({
    ...state,
    error: null,
    loaded: false,
  })),
  on(
    ContactDetailsActions.loadAdditionalContactSearchResultsSuccess,
    (state, { additionalContactSearchResults }) =>
      contactDetailsAdapter.addAll(additionalContactSearchResults, {
        ...state,
        additionalContactSearchResults,
        loaded: true,
      })
  ),
  on(
    ContactDetailsActions.loadAdditionalContactSearchResultsFailure,
    (state, { error }) => ({
      ...state,
      error,
    })
  ),
  on(
    ContactDetailsActions.updateAdditionalContact,
    (state, { selectedAdditionalContact }) => {
      return Object.assign({}, state, { selectedAdditionalContact });
    }
  ),
  on(ContactDetailsActions.clearStateObject, () => {
    return Object.assign({});
  })
);

export function reducer(
  state: ContactDetailsState | undefined,
  action: Action
) {
  return contactDetailsReducer(state, action);
}
