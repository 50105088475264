import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'sortDropdownById' })
export class SortDropdownByIdPipe implements PipeTransform {
  transform(dropdownArray: any[]): any[] {
    if (Array.isArray(dropdownArray) && dropdownArray.length > 0) {
      const copy: any[] = [...dropdownArray];
      copy.sort((a, b) =>
        Number(a.id) > Number(b.id) ? 1 : Number(b.id) > Number(a.id) ? -1 : 0
      );
      return copy;
    }
  }
}
