import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LookupService {
  /**
   * Return everything up to the first '-' in the string
   * @param selectedLookupResult
   */
  public getID(selectedLookupResult: string) {
    return selectedLookupResult.split('-')[0];
  }

  /**
   * Return everything after the first '-' in the string
   * @param selectedLookupResult
   */
  public getDescription(selectedLookupResult: string) {
    return selectedLookupResult.substring(
      selectedLookupResult.indexOf('-') + 1
    );
  }

  formatSelectedOpportunityIds(
    selectedOpportunityIds: Array<{ chip: string }>
  ): Array<{ id: string; description: string; chip: string }> {
    return selectedOpportunityIds.map(({ chip }) => {
      return {
        chip: chip,
        description: this.getDescription(chip),
        id: this.getID(chip),
      };
    });
  }
}
