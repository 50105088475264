import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { BackOfficeStateModule } from './state/back-office/back-office-state.module';
import { CommonModule } from '@angular/common';
import { FrontOfficeStateModule } from './state/front-office/front-office-state.module';
import { HttpService } from './http';
import { ReferenceDataService } from './reference-data';
import { StateModule } from './state/state.module';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    StateModule,
    FrontOfficeStateModule,
    BackOfficeStateModule,
  ],
  providers: [HttpService, ReferenceDataService],
})
export class CoreDataModule {}
