<div>
  <ioh-input
    label="Organization Name"
    inputId="org"
    name="Organization Name"
    [formGroup]="parentFormGroup"
    value="organizationName"
    width="100%"
    fxFlex="50%"
    required=true
    class="org"
    maxLength="100"
  ></ioh-input>
  <ioh-dropdown
    fxFlex="50%"
    label="Category"
    dropdownId="category"
    [formGroup]="parentFormGroup"
    isMultiple=false
    field='category'
    [values]="externalOrganizationsCategories$ | sortDropdown"
    placeholder='Please select a category'
    direction="column"
    name='category'
    class="category-dropdown"
  ></ioh-dropdown>
</div>
