import * as fromAdditionalNotes from './additional-notes/additional-notes.reducer';
import * as fromAttendeeDetails from './attendee-details/attendee-details.reducer';
import * as fromContactDetails from './contact-details/contact-details.reducer';
import * as fromEngagementDetails from './engagement-details/engagement-details.reducer';
import * as fromForm from './form/form.reducer';
import * as fromVisitDetails from './visit-details/visit-details.reducer';
import { AdditionalNotesFacade } from './additional-notes/additional-notes.facade';
import { AttendeeDetailsEffects } from './attendee-details/attendee-details.effects';
import { AttendeeDetailsFacade } from './attendee-details/attendee-details.facade';
import { CommonModule } from '@angular/common';
import { ContactDetailsEffects } from './contact-details/contact-details.effects';
import { ContactDetailsFacade } from './contact-details/contact-details.facade';
import { EffectsModule } from '@ngrx/effects';
import { EngagementDetailsEffects } from './engagement-details/engagement-details.effects';
import { EngagementDetailsFacade } from './engagement-details/engagement-details.facade';
import { FormEffects } from './form/form.effects';
import { FormFacade } from './form/form.facade';
import { NgModule } from '@angular/core';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { VisitDetailsEffects } from './visit-details/visit-details.effects';
import { VisitDetailsFacade } from './visit-details/visit-details.facade';
@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([
      ContactDetailsEffects,
      EngagementDetailsEffects,
      VisitDetailsEffects,
      AttendeeDetailsEffects,
      FormEffects,
    ]),
    StoreModule.forFeature(
      fromAdditionalNotes.ADDITIONALNOTES_FEATURE_KEY,
      fromAdditionalNotes.reducer
    ),
    StoreModule.forFeature(
      fromAttendeeDetails.ATTENDEEDETAILS_FEATURE_KEY,
      fromAttendeeDetails.reducer
    ),
    StoreModule.forFeature(
      fromContactDetails.CONTACTDETAILS_FEATURE_KEY,
      fromContactDetails.reducer
    ),
    StoreModule.forFeature(
      fromEngagementDetails.ENGAGEMENTDETAILS_FEATURE_KEY,
      fromEngagementDetails.reducer
    ),
    StoreModule.forFeature(
      fromVisitDetails.VISITDETAILS_FEATURE_KEY,
      fromVisitDetails.reducer
    ),
    StoreModule.forFeature(fromForm.FORM_FEATURE_KEY, fromForm.reducer),
    StoreDevtoolsModule.instrument({ maxAge: 10 }),
  ],
  providers: [
    AdditionalNotesFacade,
    AttendeeDetailsFacade,
    ContactDetailsFacade,
    EngagementDetailsFacade,
    VisitDetailsFacade,
    FormFacade,
  ],
})
export class FrontOfficeStateModule {}
