import {
  ENGAGEMENTDETAILS_FEATURE_KEY,
  EngagementDetailsPartialState,
  EngagementDetailsState,
} from './engagement-details.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

// Lookup the 'EngagementDetails' feature state managed by NgRx
export const getEngagementDetailsState = createFeatureSelector<
  EngagementDetailsPartialState,
  EngagementDetailsState
>(ENGAGEMENTDETAILS_FEATURE_KEY);

export const getAllOpportunityIds = createSelector(
  getEngagementDetailsState,
  (state: EngagementDetailsState) => state.opportunityIdsSearchResults
);

export const getAllInternalPurposes = createSelector(
  getEngagementDetailsState,
  (state: EngagementDetailsState) => state.internalPurposeSearchResults
);
