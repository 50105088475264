import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer } from '@angular/forms';

@Component({
  selector: 'ioh-details-attendee',
  styleUrls: ['./details-attendee.component.scss'],
  templateUrl: './details-attendee.component.html',
})
export class DetailsAttendeeComponent implements OnInit {
  parentForm;
  @Input() radioValues: any;
  @Input() requiredAttendeeNumber: boolean;
  @Output() totalAttendee = new EventEmitter();
  constructor(private readonly controlContainer: ControlContainer) {}

  ngOnInit() {
    this.parentForm = this.controlContainer.control;
  }

  updateTotal(totalAttendee: number) {
    this.totalAttendee.emit(totalAttendee);
  }
}
