import {
  ADDITIONALNOTES_FEATURE_KEY,
  AdditionalNotesPartialState,
  AdditionalNotesState,
} from './additional-notes.reducer';
import { createFeatureSelector } from '@ngrx/store';

// Lookup the 'AdditionalNotes' feature state managed by NgRx
export const getAdditionalNotesState = createFeatureSelector<
  AdditionalNotesPartialState,
  AdditionalNotesState
>(ADDITIONALNOTES_FEATURE_KEY);
