import { FormArray, ValidatorFn } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LookUpValidatorsService {
  validateRequired(arrayControl: FormArray): { [s: string]: boolean } {
    if (arrayControl.value.length === 0) {
      return { required: true };
    }
    return null;
  }

  validateLimit(maxLimit: number): ValidatorFn {
    return (arrayControl: FormArray): { [s: string]: boolean } => {
      if (arrayControl.value.length > maxLimit) {
        return { maxLimitReached: true };
      }
      return null;
    };
  }
}
