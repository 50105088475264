import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  public snackbarReference: MatSnackBarRef<any>;

  constructor(private readonly _snackBar: MatSnackBar) {}

  public openSnackBar({
    message,
    action,
    duration = 3000,
  }: {
    message: string;
    action?: string;
    duration?: number;
  }) {
    this.snackbarReference = this._snackBar.open(message, action, {
      duration,
      horizontalPosition: 'right',
      verticalPosition: 'top',
    });
  }
}
