import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class WBSValidators {
  /**
   * Gets the breakdownType of the first element in the FormArray
   * If the breakdownType is % then make sure that the sum of
   * all WBSEntries totals to 100. If greater than 100, then throw an error.
   * @param formArray
   */
  public percentageControlsValidator(formArray?: FormArray) {
    const formArrayValue = formArray?.value;
    if (formArrayValue) {
      // get the breakdownType of the first element in the wbsFormArray,
      // if there is no first element, default to undefined and return null
      const [{ breakdownType = undefined } = {}] = formArrayValue;
      if (breakdownType == '%') {
        const values = formArray.controls.map(
          (control: FormControl) => +control.value.breakdownValue
        );
        const amount = values.reduce((sum, v) => sum + v, 0);
        return amount > 100 ? { maxPercentageError: true } : null;
      } else return null;
    } else return null;
  }

  /**
   * The total sum of all WBS Entries breakdownValues MUST equal
   * the recoveryTotal from the recoveryGroup:FormGroup recoveryEntries:FormArray
   * to be able to accurately cross validate these values, we have to add the validation
   * to the recoveryGroup: FormGroup.
   * To try and keep it performant(ish), only validate if breakdownType is $, otherwise just exit
   * out with null.
   * NOTE: The error will surface on the recoveryGroup:FormGroup rather than on the WbsFormArray itself
   * @param groupControl: FormGroup
   */
  public validateBreakDownValueAgainstRecoveryTotal(groupControl?: FormGroup) {
    const groupValue = groupControl?.value;
    if (groupValue) {
      const { wbsFormArray = [], recoveryTotal = undefined } = groupValue;
      // get the breakdownType of the first element in the wbsFormArray,
      // if there is no first element, default to undefined and return null
      const [{ breakdownType = undefined } = {}] = wbsFormArray;
      if (breakdownType == '$') {
        const accumulateWBSBreakDownValue = wbsFormArray.reduce(
          (sum: number, v) => sum + Number(v.breakdownValue),
          0
        );
        return accumulateWBSBreakDownValue === recoveryTotal
          ? null
          : { invalidBreakdownTotal: true };
      } else return null;
    } else return null;
  }
}
