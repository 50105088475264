import {
  ATTENDEEDETAILS_FEATURE_KEY,
  AttendeeDetailsPartialState,
  AttendeeDetailsState,
} from './attendee-details.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

// Lookup the 'AttendeeDetails' feature state managed by NgRx
export const getAttendeeDetailsState = createFeatureSelector<
  AttendeeDetailsPartialState,
  AttendeeDetailsState
>(ATTENDEEDETAILS_FEATURE_KEY);

export const getAttendeeDetailsLoaded = createSelector(
  getAttendeeDetailsState,
  (state: AttendeeDetailsState) => state.loaded
);

export const getAllOrganizationCategories = createSelector(
  getAttendeeDetailsState,
  (state: AttendeeDetailsState) => state.categoriesResults
);

export const getAllOperatingGroupResults = createSelector(
  getAttendeeDetailsState,
  (state: AttendeeDetailsState) => state.operatingGroupResults
);

export const getAllAccentureEntities = createSelector(
  getAttendeeDetailsState,
  (state: AttendeeDetailsState) => state.accentureEntitiesResults
);

export const getAllOrganizationTypes = createSelector(
  getAttendeeDetailsState,
  (state: AttendeeDetailsState) => state.organizationTypesResults
);

export const getAllClientOrganizationSearchResults = createSelector(
  getAttendeeDetailsState,
  (state: AttendeeDetailsState) => state.clientOrganizationSearchResults
);

export const getAttendeeDetailsError = createSelector(
  getAttendeeDetailsState,
  (state: AttendeeDetailsState) => state.error
);
