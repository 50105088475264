<div [formGroup]="parentFormGroup">
  <ioh-dropdown
    fxFlex="50%"
    label="Accenture Entity"
    dropdownId="entity"
    [formGroup]="parentFormGroup"
    isMultiple=false
    field='accentureEntity'
    [values]='accentureEntitiesResults$'
    placeholder='Please select a entity'
    direction="column"
    name='entity'
  ></ioh-dropdown>
  </div>
