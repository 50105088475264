import {
  VISITDETAILS_FEATURE_KEY,
  VisitDetailsPartialState,
  VisitDetailsState,
} from './visit-details.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

// Lookup the 'VisitDetails' feature state managed by NgRx
export const getVisitDetailsState = createFeatureSelector<
  VisitDetailsPartialState,
  VisitDetailsState
>(VISITDETAILS_FEATURE_KEY);

export const getVisitDetailsLoaded = createSelector(
  getVisitDetailsState,
  (state: VisitDetailsState) => state.loaded
);

export const getVisitDetailsError = createSelector(
  getVisitDetailsState,
  (state: VisitDetailsState) => state.error
);

export const getAllWBSCodes = createSelector(
  getVisitDetailsState,
  (state: VisitDetailsState) => state.wbsCodeResults
);
