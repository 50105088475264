import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
/**
 * TODO ref ref data service to use fetch/data-persistence
 */
// import { fetch } from '@nrwl/angular';
import { of } from 'rxjs';

import * as VisitDetailsActions from './visit-details.actions';
import { ReferenceDataService } from '../../../reference-data';
import { catchError, exhaustMap, map } from 'rxjs/operators';

@Injectable()
export class VisitDetailsEffects {
  loadWBSResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VisitDetailsActions.loadWBSLookupResults),
      exhaustMap((action) =>
        this.referenceDataService.getWBS(action.wbs).pipe(
          map((res) => {
            const response = {
              ...res.data,
              WbsComponentIndex: action.index,
            };
            return VisitDetailsActions.loadWBSLookupResultsSuccess({
              wbsCodeResults: response,
            });
          }),
          catchError((error) =>
            of(
              VisitDetailsActions.loadWBSLookupResultsFailure({ error: error })
            )
          )
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly referenceDataService: ReferenceDataService
  ) {}
}
