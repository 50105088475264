import * as AdditionalNotesActions from './additional-notes.actions';
import * as AdditionalNotesSelectors from './additional-notes.selectors';
import * as fromAdditionalNotes from './additional-notes.reducer';
import { Action, Store, select } from '@ngrx/store';
import { Injectable } from '@angular/core';

@Injectable()
export class AdditionalNotesFacade {
  constructor(
    private readonly store: Store<
      fromAdditionalNotes.AdditionalNotesPartialState
    >
  ) {}

  allAdditionalNotes$ = this.store.pipe(
    select(AdditionalNotesSelectors.getAdditionalNotesState)
  );

  dispatch(action: Action) {
    this.store.dispatch(action);
  }

  updateComments(value) {
    this.dispatch(
      AdditionalNotesActions.updateComments({ additionalComments: value })
    );
  }

  clearStateObject() {
    this.dispatch(AdditionalNotesActions.clearStateObject());
  }
}
