import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SortDropdownByIdPipe } from './sort-dropdown-byid.pipe';
import { SortDropdownPipe } from './sort-dropdown.pipe';
import { SortOperationGroupPipe } from './operation-group.pipe';

@NgModule({
  declarations: [
    SortDropdownPipe,
    SortDropdownByIdPipe,
    SortOperationGroupPipe,
  ],
  exports: [SortDropdownPipe, SortDropdownByIdPipe, SortOperationGroupPipe],
  imports: [CommonModule],
})
export class PipeModule {}
