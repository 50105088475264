import * as AdditionalNotesActions from './additional-notes.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

export const ADDITIONALNOTES_FEATURE_KEY = 'additionalNotes';

export interface AdditionalNotesState extends EntityState<any> {
  selectedId?: string | number; // which AdditionalNotes record has been selected
  loaded: boolean; // has the AdditionalNotes list been loaded
  error?: string | null; // last none error (if any)
  additionalComments: any | null;
}

export interface AdditionalNotesPartialState {
  readonly [ADDITIONALNOTES_FEATURE_KEY]: AdditionalNotesState;
}

export const additionalNotesAdapter: EntityAdapter<any> = createEntityAdapter<
  any
>();

export const initialState: AdditionalNotesState = additionalNotesAdapter.getInitialState(
  {
    additionalComments: '',
    // set initial required properties
    loaded: false,
  }
);

const additionalNotesReducer = createReducer(
  initialState,
  on(AdditionalNotesActions.updateComments, (state, { additionalComments }) => {
    return Object.assign({}, state, { additionalComments });
  }),
  on(AdditionalNotesActions.clearStateObject, () => {
    return Object.assign({});
  })
);

export function reducer(
  state: AdditionalNotesState | undefined,
  action: Action
) {
  return additionalNotesReducer(state, action);
}
