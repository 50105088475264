export const browser = () => {
  const agent = window.navigator.userAgent.toLowerCase();
  switch (true) {
    case agent.includes('edge'):
      return 'edge';
    case agent.includes('opr'):
      return 'opera';
    case agent.includes('chrome'):
      return 'chrome';
    case agent.includes('trident'):
      return 'ie';
    case agent.includes('firefox'):
      return 'firefox';
    case agent.includes('safari'):
      return 'safari';
    default:
      return 'other';
  }
};
