import { Component, Input } from '@angular/core';

@Component({
  selector: 'ioh-error',
  styleUrls: ['./error.component.scss'],
  templateUrl: './error.component.html',
})
export class ErrorComponent {
  @Input() errorMsg: string;
}
