import { ContactDetailsEntity } from './contact-details.models';
import { createAction, props } from '@ngrx/store';

export const loadContactDetails = createAction(
  '[ContactDetails] Load ContactDetails',
  props<{
    searchTerm: { searchtxt: string; requestid: number; exactmatch: number };
  }>()
);

export const loadContactDetailsSuccess = createAction(
  '[ContactDetails] Load ContactDetails Success',
  props<{ contactDetails: ContactDetailsEntity[] }>()
);

export const loadContactDetailsFailure = createAction(
  '[ContactDetails] Load ContactDetails Failure',
  props<{ error: any }>()
);

/**
 * Engagement Sponsor
 */

export const loadEngagementSponsorSearchResults = createAction(
  '[ContactDetails] Load EngagementSponsorSearchResults',
  props<{
    searchTerm: { searchtxt: string; requestid: number; exactmatch: number };
  }>()
);
export const loadEngagementSponsorSearchResultsSuccess = createAction(
  '[ContactDetails] Load EngagementSponsorSearchResults Success',
  props<{ engagementSponsorSearchResults: any }>()
);
export const loadEngagementSponsorSearchResultsFailure = createAction(
  '[ContactDetails] Load EngagementSponsorSearchResults Failure',
  props<{ error: any }>()
);

export const updateEngagementSponsors = createAction(
  '[ContactDetails] Update SelectedEngagementSponsor',
  props<{ selectedEngagementSponsor: any }>()
);

/**
 * Primary POC
 */
export const loadPrimaryContactSearchResults = createAction(
  '[ContactDetails] Load PrimaryContactSearchResults',
  props<{
    searchTerm: { searchtxt: string; requestid: number; exactmatch: number };
  }>()
);
export const loadPrimaryContactSearchResultsSuccess = createAction(
  '[ContactDetails] Load PrimaryContactSearchResults Success',
  props<{ primaryContactSearchResults: any }>()
);
export const loadPrimaryContactSearchResultsFailure = createAction(
  '[ContactDetails] Load PrimaryContactSearchResults Failure',
  props<{ error: any }>()
);

export const updatePrimaryContact = createAction(
  '[ContactDetails] Update PrimaryContact',
  props<{ selectedPrimaryContact: any }>()
);

/**
 * Additional Contacts
 */
export const loadAdditionalContactSearchResults = createAction(
  '[ContactDetails] Load AdditionalContactsSearchResults',
  props<{
    searchTerm: { searchtxt: string; requestid: number; exactmatch: number };
  }>()
);
export const loadAdditionalContactSearchResultsSuccess = createAction(
  '[ContactDetails] Load AdditionalContactsSearchResults Success',
  props<{ additionalContactSearchResults: any }>()
);
export const loadAdditionalContactSearchResultsFailure = createAction(
  '[ContactDetails] Load AdditionalContactsSearchResults Failure',
  props<{ error: any }>()
);
export const updateAdditionalContact = createAction(
  '[ContactDetails] Update SelectedAdditionalContact',
  props<{ selectedAdditionalContact: any }>()
);

export const clearStateObject = createAction(
  '[ContactDetails] Clear state object'
);
