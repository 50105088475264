import { createAction, props } from '@ngrx/store';

export const updateComments = createAction(
  '[AdditionalNotes] Update Comments',
  props<{ additionalComments: any }>()
);

export const clearStateObject = createAction(
  '[AdditionalNotes] Clear State Object'
);
