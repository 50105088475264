import { AbstractControl, ControlContainer } from '@angular/forms';
import { Component, Input } from '@angular/core';
@Component({
  selector: 'ioh-internal',
  styleUrls: ['./internal.component.scss'],
  templateUrl: './internal.component.html',
})
export class InternalComponent {
  parentFormGroup: AbstractControl;

  constructor(private readonly controlContainer: ControlContainer) {}

  @Input() accentureEntitiesResults$;

  ngOnInit() {
    this.parentFormGroup = this.controlContainer.control;
  }
}
