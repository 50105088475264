import { Component, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
@Component({
  selector: 'ioh-shell',
  styleUrls: ['./shell.component.scss'],
  templateUrl: './shell.component.html',
})
export class ShellComponent {
  response: boolean;

  constructor(private readonly router: Router) {
    this.response =
      this.router.getCurrentNavigation().extras.state.response || null;
  }

  @HostListener('window:popstate', ['$event'])
  onArrowBackClick(event) {
    this.goBack();
  }

  goBack() {
    // TO DO: to be revisited in Phase 2
    if (window.history.length > 1) {
      const programId = sessionStorage.getItem('programId');
      const url = `request-form?program=${programId}`;
      this.router.navigateByUrl(url);
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          window.location.reload();
        }
      });
    }
  }
}
